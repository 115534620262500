import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useParams } from "react-router-dom";

const ViewStudent = () => {
  const { studentID } = useParams();

  const table = useTable("purchaseHistory", "", "", "", "", studentID);
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students | Mr Ibrahim Abdel Masseh"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          View student
        </h2>
      </div>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default ViewStudent;
