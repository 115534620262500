import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import Register from "../../../Student/SignIn/Register.jsx";
import { useEffect, useReducer, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
function PasswordForm() {
  const [hide, setHide] = useState(false);
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPassword": {
        return {
          ...state,
          submitEditPassword: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPassword: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [EditPasswordSuccess, EditPasswordErrors, PasswordLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PASSWORD_API,
    "POST",
    state.submitEditPassword.flag,
    state.submitEditPassword.dependency,
    state.submitEditPassword.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPassword",
      payload: {
        flag: "submitEditPassword",
        dependency: !state.submitEditPassword.dependency,
        data: { ...data, id: studentID },
      },
    });
  };
  return (
    <form
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-6 rounded-3xl bg-white p-16 shadow-3xl mt-24"
    >
      <div className="flex flex-row-reverse w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
        {/** Confirm Password  */}

        <div className="w-1/2  md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2  ">
            <label htmlFor="password_confirmation" className="text-start">
              Confirm password
            </label>

            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password_confirmation"
                className="signin-inputs  w-full pl-4"
                type={`${hide ? "text" : "password"}`}
                placeholder="Confirm password"
                name="password_confirmation"
                autoComplete="on"
                {...register("password_confirmation", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                  validate: (value) => value === getValues("password"),
                })}
              />

              <div
                //!eye icons
                onClick={() => setHide(!hide)}
                className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer"
              >
                {hide ? (
                  <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="h-full w-full"
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </div>
          </div>

          {errors.password_confirmation && (
            <p className="mt-2 w-full  text-[12px] text-blue-900">
              {errors.password_confirmation.type === "required" &&
                "Please fill out this field"}
              {errors.password_confirmation.type === "maxLength" &&
                "The maximum number of characters is 25 characters"}
              {errors.password_confirmation.type === "minLength" &&
                "The minimum number of characters is 6 characters"}
              {errors.password_confirmation.type === "validate" &&
                "Passwords do not match"}
            </p>
          )}

          {
            //!-------Editing server errors -----

            EditPasswordErrors?.response?.data?.errors &&
              EditPasswordErrors?.response?.data?.errors
                ?.password_confirmation && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {
                    EditPasswordErrors?.response?.data.errors
                      .password_confirmation[0]
                  }
                </p>
              )
          }
        </div>
        {/** Password  */}

        <div className="w-1/2 md:order-2 md:w-full">
          <div className="relative flex flex-col items-start justify-center gap-2 ">
            <label htmlFor="password" className="text-start">
              Password
            </label>
            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password"
                className="signin-inputs w-full pl-4"
                type={`${hide ? "text" : "password"}`}
                placeholder="Password"
                name="password"
                autoComplete="on"
                {...register("password", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                })}
              />

              <div
                //!eye icons
                onClick={() => setHide(!hide)}
                className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="h-full w-full"
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </div>
          </div>
          {errors.password && (
            <p className="mt-2 w-full  text-[12px] text-blue-900">
              {errors.password.type === "required" &&
                "Please fill out this field"}
              {errors.password.type === "maxLength" &&
                "The maximum number of characters is 25 characters"}
              {errors.password.type === "minLength" &&
                "The minimum number of characters is 6 characters"}
            </p>
          )}

          {
            //!-------Editing server errors -----

            EditPasswordErrors?.response?.data?.errors &&
              EditPasswordErrors?.response?.data?.errors?.password && (
                <p className="w-full text-[12px] text-blue-900  ">
                  {EditPasswordErrors?.response?.data.errors.password[0]}
                </p>
              )
          }
        </div>
      </div>
      {/** Submit Button */}

      <button
        disabled={
          !isValid || (state.submitEditPassword.flag && PasswordLoading)
        }
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPassword.flag && PasswordLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit password"
        )}
      </button>
    </form>
  );
}
function ImageForm({ values }) {
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditPhoto": {
        return {
          ...state,
          submitEditPhoto: action.payload,
        };
      }
      case "setUploadedImgSrc": {
        return {
          ...state,
          uploadedImgSrc: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditPhoto: {
      flag: "",
      dependency: false,
      data: {},
    },
    uploadedImgSrc: null,
  });

  const [EditPhotoSuccess, EditPhotoErrors, PhotoLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_PHOTO_API,
    "POST",
    state.submitEditPhoto.flag,
    state.submitEditPhoto.dependency,
    state.submitEditPhoto.data,
    true
  );
  useEffect(() => {
    if (EditPhotoSuccess) {
      URL.revokeObjectURL(state.uploadedImgSrc);
    }
  }, [EditPhotoSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    values,
  });
  const imgInput = watch("profile_pic");
  useEffect(() => {
    const subscription = watch((input) => {
      if (input.profile_pic[0]?.name?.length > 0) {
        let imageUrl = URL.createObjectURL(input.profile_pic[0]);
        dispatch({
          type: "setUploadedImgSrc",
          payload: imageUrl,
        });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);
  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditPhoto",
      payload: {
        flag: "SubmitEditPhoto",
        dependency: !state.submitEditPhoto.dependency,
        data: { profile_pic: data.profile_pic[0], id: studentID },
      },
    });
  };
  return (
    <form
      encType="multipart/form-data"
      method="post"
      onSubmit={handleSubmit(onSubmit)}
      className="w-[320px] flex   flex-col items-start justify-center gap-6 rounded-3xl bg-white p-16 shadow-3xl mt-24"
    >
      {/**  Profile Picture  */}
      <div className="w-full flex flex-col items-center">
        <div className="w-fit ">
          <label htmlFor="profile_pic">
            <div className="profile_pic h-36 aspect-square overflow-hidden rounded-full border-2 border-secondary p-1 cursor-pointer">
              <img
                className="h-full w-full rounded-full object-cover object-top"
                src={state.uploadedImgSrc || values?.profile_pic}
                alt="Student Avatar"
              />
            </div>
          </label>
        </div>
        <input
          id="profile_pic"
          hidden
          className="signin-inputs  w-full"
          type="file"
          accept="image/jpg, image/jpeg, image/png, image/webp"
          name="profile_pic"
          {...register("profile_pic", {
            required: true,
            validate: (value) => !(value[0]?.size > 50000000),
          })}
        />

        {errors.profile_pic && (
          <p className="pt-4 text-xs text-blue-500">
            {errors.profile_pic.type === "required" &&
              "This field is required."}
            {errors.profile_pic.type === "validate" && "Max Image Size is 5MB."}
          </p>
        )}
        {
          //!-------Editing server errors -----

          EditPhotoErrors?.response?.data?.errors &&
            EditPhotoErrors?.response?.data?.errors?.profile_pic && (
              <p className="w-full  text-[12px] text-blue-900  ">
                {EditPhotoErrors?.response?.data.errors.profile_pic[0]}
              </p>
            )
        }
      </div>

      {/** Submit Button */}

      <button
        disabled={!isValid || (state.submitEditPhoto.flag && PhotoLoading)}
        className=" submit mt-6 w-full "
        type="submit"
      >
        {state.submitEditPhoto.flag && PhotoLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          "Edit profile picture"
        )}
      </button>
    </form>
  );
}

function BalanceForm({ values }) {
  const { studentID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitEditBalance": {
        return {
          ...state,
          submitEditBalance: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitEditBalance: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  const [EditBalanceSuccess, EditBalanceErrors, BalanceLoading] = useAxios(
    process.env.REACT_APP_EDIT_STUDENT_AMOUNT_API,
    "POST",
    state.submitEditBalance.flag,
    state.submitEditBalance.dependency,
    state.submitEditBalance.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    dispatch({
      type: "setSubmitEditBalance",
      payload: {
        flag: "submitEditBalance",
        dependency: !state.submitEditBalance.dependency,
        data: {
          ...data,
          id: studentID,
          amount:
            +data.transaction_type === 0 ? -Math.abs(data.amount) : data.amount,
        },
      },
    });
  };

  useEffect(() => {
    if (EditBalanceSuccess) {
      reset();
    }
  }, [EditBalanceSuccess, reset]);

  const table = useTable(
    "allStudnetBalance",
    "",
    "",
    EditBalanceSuccess,
    "",
    studentID
  );

  return (
    <>
      <form
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        className=" md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-6 rounded-3xl bg-white p-16 shadow-3xl mt-24"
      >
        <div
          className={`flex w-full flex-col items-end justify-center gap-2  md:w-full `}
        >
          <label className="w-full truncate" htmlFor={`transaction_type`}>
            Transaction Type
          </label>

          <select
            name="transaction_type"
            id="transaction_type"
            {...register("transaction_type", { required: true })}
          >
             <option value={1}>Deposit</option>
            <option value={0}>Withdraw</option>
           
          </select>
          {errors?.sessions?.transaction_type && (
            <p className="text-[12px] text-blue-900 ">
              {errors?.sessions?.transaction_type.type === "required" &&
                "Please fill out this field"}
            </p>
          )}
          {
            //!-------server errors -----

            EditBalanceErrors &&
              EditBalanceErrors?.response?.data?.errors?.transaction_type && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {EditBalanceErrors?.response?.data?.errors?.transaction_type}
                </p>
              )
          }
        </div>

        <div className="flex flex-row-reverse w-full items-start justify-between gap-16 md:flex-col md:gap-6 ">
          <div className="w-full  md:w-full">
            <div className="relative flex flex-col items-start justify-center gap-2  ">
              <label htmlFor="amount" className="text-start">
                Balance
              </label>

              <div className="relative-hide relative h-auto w-full">
                <input
                  id="amount"
                  className="signin-inputs  w-full pl-4"
                  type="number"
                  placeholder="Balance"
                  name="amount"
                  autoComplete="on"
                  {...register("amount", {
                    required: true,
                    // maxLength: 25,
                    minLength: 0,
                  })}
                />
              </div>
            </div>

            {errors.amount && (
              <p className="mt-2 w-full  text-[12px] text-blue-900">
                {errors.amount.type === "required" &&
                  "Please fill out this field"}
                {errors.amount.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.amount.type === "minLength" &&
                  "The minimum number of characters is 6 characters"}
                {errors.amount.type === "validate" && "Passwords do not match"}
              </p>
            )}

            {
              //!-------Editing server errors -----

              EditBalanceErrors?.response?.data?.errors &&
                EditBalanceErrors?.response?.data?.errors?.amount && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {EditBalanceErrors?.response?.data.errors.amount[0]}
                  </p>
                )
            }
          </div>
        </div>

        <button
          disabled={
            !isValid || (state.submitEditBalance.flag && BalanceLoading)
          }
          className=" submit mt-6 w-full "
          type="submit"
        >
          {state.submitEditBalance.flag && BalanceLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "Edit Balance"
          )}
        </button>
      </form>

      <section className="h-full w-full overflow-y-auto px-20 py-20  dark:bg-dark dark:text-light sm:px-10">
        <div className="h-fit  w-full">{table}</div>
      </section>
    </>
  );
}

function EditStudent() {
  const AuthState = useAuthState();
  const { studentID } = useParams();
  const [editStudentForm, setEditStudentForm] = useState("info");
  //!--------- get the student info for editing -------
  const [studentInfo, studentInfoErrors, studentInfoLoading] = useAxios(
    `${process.env.REACT_APP_ADMIN_STUDENT_INFO_API}${studentID}`,
    "GET",
    studentID,
    editStudentForm
  );

   return (
    <section className="h-auto w-full overflow-y-auto  dark:bg-dark dark:text-light flex flex-col items-center ">
      <div className="filters flex flex-row-reverse w-full justify-center mt-24 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
        { (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        (AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("students")
        ) && AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("balance")
        ) )
        ) &&     <button
        onClick={() => {
          setEditStudentForm("balance");
        }}
        className={` rounded-xl border-2 border-secondary  p-4 sm:w-3/4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
          editStudentForm === "balance" && "bg-secondary text-light"
        }`}
      >
        Balance
      </button> }
    
        {/* 
        <button
          onClick={() => {
            setEditStudentForm("img");
          }}
          className={` rounded-xl border-2 border-secondary  p-4 sm:w-3/4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            editStudentForm === "img" && "bg-secondary text-light"
          }`}
        >
          Personal photo
        </button> */}

        <button
          onClick={() => {
            setEditStudentForm("password");
          }}
          className={` rounded-xl border-2 border-secondary  p-4 sm:w-3/4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            editStudentForm === "password" && "bg-secondary text-light"
          }`}
        >
          Password
        </button>
        <button
          onClick={() => {
            setEditStudentForm("info");
          }}
          className={` rounded-xl border-2 border-secondary  p-4 sm:w-3/4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
            editStudentForm === "info" && "bg-secondary text-light"
          }`}
        >
          Basic information
        </button>
      </div>
      {editStudentForm === "info" ? (
        <Register
          edit
          StudentInfo={studentInfo}
          studentInfoLoading={studentInfoLoading}
        />
      ) : editStudentForm === "password" ? (
        <PasswordForm />
      ) : editStudentForm === "img" ? (
        <ImageForm
          values={{
            profile_pic: studentInfo?.data[0]?.profile_pic,
          }}
        />
      ) : editStudentForm === "balance" ? (AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        (AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("students")
        ) && AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("balance")
        ) )
        ) && (
        <BalanceForm />
      ) : null}
    </section>
  );
}

export default EditStudent;
