
import { Link, useParams } from "react-router-dom";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelmetTags from "../../../../MainComponents/HelmetTags";

function AdminLecture() {
  const AuthState = useAuthState();
  const { lectureID } = useParams();
  const table = useTable("lectures", "", "", "", "", lectureID);

  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Lectures | Mr Ibrahim Abdel Masseh"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <div className="top-btns flex w-full justify-start gap-10 asm:flex-col asm:items-center asm:justify-start asm:gap-14">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("content")
            )) && (
            <Link
              to={`/admin/courses/sessions/${lectureID}/videos`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Videos
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("content")
            )) && (
            <Link
              to={`/admin/courses/sessions/${lectureID}/materials`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Attachments
            </Link>
          )}
          {/* {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("courses_addHw")
            )) && (
            <Link
              to={`/admin/courses/sessions/${lectureID}/homework`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Homework
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("courses_addquiz")
            )) && (
            <Link
              to={`/admin/courses/sessions/${lectureID}/quiz`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Quiz
            </Link>
          )} */}
        </div>
        <hr className="w-3/4 border-[1px] border-secondary" />
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("add_student_to_courses")
          )) && (
          <div className="bottom-btn flex w-full justify-start asm:justify-center">
            <Link
              to={`/admin/courses/sessions/${lectureID}/add-student`}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Student
            </Link>
          </div>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        AuthState.userData?.admin?.permissions?.some((permission) =>
          permission.name.startsWith("content")
        )) && <div className="h-fit  w-full">{table}</div>}
    </section>
  );
}

export default AdminLecture;
